import { mapGetters, mapMutations } from "vuex"
export default {
  name: "Consent",
  data() {
    return {
      showConsentAlert: false,
      consentGiven    : null
    }
  },
  computed: {
    ...mapGetters({
      channels        : "channels/channels",
      reporterLanguage: "auth/reporterLanguage"
    }),
    channel() {
      return this.channels.find(channel => channel.name === this.$route.params.channel)
    },
    isMobileView() {
      return this.$vuetify.breakpoint.smAndDown
    },
    getClassForCheckboxLabel() {
      return this.showConsentAlert ? "error--text text--darken-2" : "grey--text text--darken-4"
    }
  },
  methods: {
    ...mapMutations({
      setConsentGiven        : "reports/setConsentGiven",
      setShowPrivacyStatement: "channels/setShowPrivacyStatement"
    }),
    handleCloseNotification() {
      this.showConsentAlert = false
    },
    handleConsentGiven() {
      if (this.consentGiven) {
        this.setConsentGiven(true)
        this.$router.push({
          name  : "organisation-code",
          params: {
            locale : this.reporterLanguage.shortName,
            channel: this.$route.params.channel
          }
        })
      } else {
        this.showConsentAlert = true
      }
    },
    handlePrivacyStatement(value) {
      this.setShowPrivacyStatement(value)
    }
  }
}
